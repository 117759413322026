import React from 'react';
import { useDarkMode } from 'context/DarkModeContext';

const SubMenuItem = ({ type, name, handleInputChange, isSelected }) => {
  const { darkMode } = useDarkMode();
  const selectedColor = darkMode ? 'var(--color-white)' : 'var(--color-black)';
  const unselectedColor = darkMode ? 'var(--color-grey)' : '';

  return (
    <li key={name}>
      <input id={name} type={'radio'} onChange={() => handleInputChange(type, name)} checked={isSelected} />
      <label htmlFor={name}>
        <span className={'checkbox'} style={{ backgroundColor: isSelected ? selectedColor : unselectedColor }}></span>
        {name && <span style={{ color: isSelected ? selectedColor : unselectedColor }}>{name}</span>}
      </label>
    </li>
  );
};

export default SubMenuItem;
