// localStorage에 저장할 layout reducer

const initialStateLayout = {
  view: 'IMAGE',
  gridColumns: 0,
};

const LAYOUT_SET_VIEW = 'LAYOUT/SET_VIEW';

const LAYOUT_SET_GRIDCOLUMS = 'LAYOUT/SET_GRIDCOLUMS';

const LAYOUT_RESET = 'LAYOUT/RESET';

const setView = view => ({ type: LAYOUT_SET_VIEW, view });

const setGridColumns = gridColumns => ({ type: LAYOUT_SET_GRIDCOLUMS, gridColumns });
const resetUserLayout = () => ({ type: LAYOUT_RESET });
export const userLayoutActionCreator = {
  setView,
  setGridColumns,
  resetUserLayout,
};

export const userLayout = (state = initialStateLayout, action) => {
  switch (action.type) {
    case LAYOUT_SET_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case LAYOUT_SET_GRIDCOLUMS:
      return {
        ...state,
        gridColumns: action.gridColumns,
      };
    case LAYOUT_RESET:
      return {
        ...initialStateLayout,
      };
    default:
      return state;
  }
};
