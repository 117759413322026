import useCheckPC from 'hooks/useCheckPC';
import React from 'react';
import { formatColors } from 'utils/parseData';
import { useSearchParams } from 'react-router-dom';

const ProductListItem = ({ product }) => {
  const { isPC, isTabletLarge, isTabletSmall, isMobile } = useCheckPC();
  const [searchParams, setSearchParams] = useSearchParams();

  const isLookbook = product.category === 'Lookbook';

  const isSelectedProduct = () => {
    if (isLookbook) {
      return searchParams.get('lookbook') === product.number;
    } else {
      return (
        (searchParams.get('product') === product.code || searchParams.get('name') === product.name) &&
        searchParams.get('color') === product.colour
      );
    }
  };

  return (
    <>
      {isLookbook ? (
        <li
          onClick={() => {
            const newSearchParams = isLookbook
              ? { lookbook: product.number }
              : { product: product.code, color: product.colour };
            if (searchParams.get('filter')) {
              newSearchParams['filter'] = searchParams.get('filter');
            }
            if (searchParams.get('sort')) {
              newSearchParams['sort'] = searchParams.get('sort');
            }
            setSearchParams(newSearchParams);
          }}
          className={`lookbook_item ${isSelectedProduct() ? 'hover' : ''}`}
        >
          {(isPC || isTabletLarge || isTabletSmall) && (
            <>
              <span>{product.number}</span>
              <span>{product.name}</span>
              <span>{product.category}</span>
            </>
          )}

          {isMobile && (
            <>
              <span>{product.number}</span>
              <span>{product.name}</span>
            </>
          )}
        </li>
      ) : (
        <li
          onClick={() => {
            const newSearchParams = isLookbook
              ? { lookbook: product.number }
              : { product: product.code, color: product.colour };
            if (searchParams.get('filter')) {
              newSearchParams['filter'] = searchParams.get('filter');
            }
            if (searchParams.get('sort')) {
              newSearchParams['sort'] = searchParams.get('sort');
            }
            setSearchParams(newSearchParams);
          }}
          className={`product_item ${isSelectedProduct() ? 'hover' : ''}`}
        >
          {(isPC || isTabletLarge || isTabletSmall) && (
            <>
              <span>{product.code}</span>
              <span>{product.name}</span>
              <span>{product.category}</span>
              <span>{formatColors(product.colors)}</span>
              <span>{product.size}</span>
              <span>{product.sort_material}</span>
            </>
          )}

          {isMobile && (
            <>
              <span>{product.code}</span>
              <span>{product.name}</span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </>
          )}
        </li>
      )}
    </>
  );
};

export default ProductListItem;
