const initialStateLayout = {
  isDrawerOpen: false,
  isHeaderOpen: false,
  isLoading: false,
  isSubMenuOpen: false,
  isFooterVisible: true,
  subMenu: {
    title: '',
    cancelButton: {
      label: 'Cancel',
    },
  },
};

const LAYOUT_OPEN_DRAWER = 'LAYOUT/OPEN_DRAWER';
const LAYOUT_CLOSE_DRAWER = 'LAYOUT/CLOSE_CART';

const LAYOUT_OPEN_HEADER = 'LAYOUT/OPEN_HEADER';
const LAYOUT_CLOSE_HEADER = 'LAYOUT/CLOSE_HEADER';

const LAYOUT_OPEN_LOADING = 'LAYOUT/OPEN_LOADING';
const LAYOUT_CLOSE_LOADING = 'LAYOUT/CLOSE_LOADING';

const LAYOUT_OPEN_SUBMENU = 'LAYOUT/OPEN_SUBMENU';
const LAYOUT_CLOSE_SUBMENU = 'LAYOUT/CLOSE_SUBMENU';

const LAYOUT_OPEN_FOOTER = 'LAYOUT/OPEN_FOOTER';
const LAYOUT_CLOSE_FOOTER = 'LAYOUT/CLOSE_FOOTER';

const LAYOUT_RESET = 'LAYOUT/RESET';

const openDrawer = () => ({ type: LAYOUT_OPEN_DRAWER, isDrawerOpen: true });
const closeDrawer = () => ({ type: LAYOUT_CLOSE_DRAWER, isDrawerOpen: false });
const openHeader = () => ({ type: LAYOUT_OPEN_HEADER });
const closeHeader = () => ({ type: LAYOUT_CLOSE_HEADER });
const openLoading = () => ({ type: LAYOUT_OPEN_LOADING, isLoading: true });
const closeLoading = () => ({ type: LAYOUT_CLOSE_LOADING, isLoading: false });
const openSubMenu = subMenu => ({ type: LAYOUT_OPEN_SUBMENU, subMenu });
const closeSubMenu = () => ({ type: LAYOUT_CLOSE_SUBMENU });
const showFooter = () => ({ type: LAYOUT_OPEN_FOOTER, isFooterVisible: true });
const hiddenFooter = () => ({ type: LAYOUT_CLOSE_FOOTER, isFooterVisible: false });
const resetLayout = () => ({ type: LAYOUT_RESET });

export const layoutActionCreator = {
  openDrawer,
  closeDrawer,
  openHeader,
  closeHeader,
  openLoading,
  closeLoading,
  openSubMenu,
  closeSubMenu,
  showFooter,
  hiddenFooter,
  resetLayout,
};

export const layout = (state = initialStateLayout, action) => {
  switch (action.type) {
    case LAYOUT_OPEN_DRAWER:
      return {
        ...state,
        isDrawerOpen: action.isDrawerOpen,
      };
    case LAYOUT_CLOSE_DRAWER:
      return {
        ...state,
        isDrawerOpen: action.isDrawerOpen,
      };
    case LAYOUT_OPEN_HEADER:
      return {
        ...state,
        isHeaderOpen: true,
      };
    case LAYOUT_CLOSE_HEADER:
      return {
        ...state,
        isHeaderOpen: false,
      };
    case LAYOUT_OPEN_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case LAYOUT_CLOSE_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case LAYOUT_OPEN_SUBMENU:
      return {
        ...state,
        isSubMenuOpen: true,
        subMenu: {
          ...state.subMenu,
          ...action.subMenu,
        },
      };
    case LAYOUT_CLOSE_SUBMENU:
      return {
        ...state,
        isSubMenuOpen: false,
        subMenu: {
          ...initialStateLayout.subMenu,
        },
      };
    case LAYOUT_OPEN_FOOTER:
      return {
        ...state,
        isFooterVisible: action.isFooterVisible,
      };
    case LAYOUT_CLOSE_FOOTER:
      return {
        ...state,
        isFooterVisible: action.isFooterVisible,
      };
    case LAYOUT_RESET:
      return {
        ...initialStateLayout,
      };
    default:
      return state;
  }
};
