import { useMediaQuery } from 'react-responsive';

const useCheckPC = () => {
  const isPC = useMediaQuery({ query: '(min-width: 1377px)' });
  const isTabletLarge = useMediaQuery({ query: '(min-width: 1025px) and (max-width: 1376px)' });
  const isTabletSmall = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1024px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return { isPC, isTabletLarge, isTabletSmall, isMobile };
};

export default useCheckPC;
