import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import ProductImage from 'components/ProductImage';

const ProductDrawer = ({ products, handleClose }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const getProduct = () => {
    if (searchParams.get('lookbook')) {
      return products.find(product => product.number === searchParams.get('lookbook')) ?? {};
    } else if (searchParams.get('product')) {
      return (
        products.find(
          product => product.code === searchParams.get('product') && product.colour === searchParams.get('color'),
        ) ?? {}
      );
    }
    return {};
  };

  const [product, setProduct] = useState(getProduct());

  useEffect(() => {
    setProduct(getProduct());
    setCurrentImageIndex(0);
  }, [searchParams]);

  const scrollableElRef = useRef(null);

  /** 이미지 슬라이드의 현재 index를 확인하는 state **/
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const isLookbook = product.category === 'Lookbook';

  const lookbookChart =
    isLookbook &&
    Object.entries(product)
      .filter(
        ([key, value]) =>
          key !== 'number' &&
          key !== 'name' &&
          key !== 'wideImage' &&
          key !== 'image_src_1' &&
          key !== 'category' &&
          (typeof value !== 'object' || (value.code !== null && value.color !== null)),
      )
      .map(([key, value]) => ({ [key]: value }));

  const productChart = !isLookbook
    ? [
        { Number: product.code },
        { Name: product.name },
        { Category: product.category },
        { Colour: product.colour },
        { Size: product.size },
        { Origin: `Made in ${product.origin}` },
        { Materials: product.materials },
      ]
    : [];

  const highlightColor = (product, key, value) => {
    if (key === 'Colour' && product.colors.includes(product.colour)) {
      return product.colors.map((selectedColor, index) => (
        <span key={uuid()}>
          {index > 0 ? ', ' : ''}
          <a
            onClick={() => setOtherColorProduct(selectedColor, product.name)}
            style={{ color: selectedColor === product.colour ? 'var(--color-text)' : 'var(--color-blue)' }}
          >
            {selectedColor}
          </a>
        </span>
      ));
    }
    return value;
  };

  /** product-detail에서 color 클릭 시, 해당 상품으로 이동하는 함수 */
  const setOtherColorProduct = (selectedColor, name) => {
    if (selectedColor === product.colors) return;

    const filteredProduct = products.find(
      product =>
        (product.code === searchParams.get('product') || product.name === name) && product.colour === selectedColor,
    );

    const newSearchParams = { product: filteredProduct.code, color: selectedColor };
    if (searchParams.get('filter')) {
      newSearchParams['filter'] = searchParams.get('filter');
    }
    if (searchParams.get('sort')) {
      newSearchParams['sort'] = searchParams.get('sort');
    }
    setSearchParams(newSearchParams);
  };

  const setLookbookProduct = ({ code, color }) => {
    const newSearchParams = { product: code, color: color };
    if (searchParams.get('filter')) {
      newSearchParams['filter'] = searchParams.get('filter');
    }
    if (searchParams.get('sort')) {
      newSearchParams['sort'] = searchParams.get('sort');
    }
    setSearchParams(newSearchParams);
  };

  const productImages = !isLookbook
    ? Object.entries(product)
        .filter(([key, value]) => key.includes('image_src') && typeof value === 'string' && value.startsWith('/'))
        .map(([key, value]) => value)
    : [];

  const handleBulletClick = index => {
    setCurrentImageIndex(index);
  };

  const handleImageClick = () => {
    setCurrentImageIndex(prevIndex => (prevIndex < productImages.length - 1 ? prevIndex + 1 : 0));
  };

  const changeFirstLetter = str => {
    if (!str) {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div id={'drawer'}>
      {isLookbook ? (
        <div ref={scrollableElRef} className={'lookbook-detail'}>
          <div>
            <span>
              {product.category} - {product.name}
            </span>
            <button onClick={handleClose}>CLOSE</button>
          </div>
          <ProductImage
            key={product.image_src_1}
            product={product}
            isLoaded={isLoaded}
            onLoad={() => {
              setIsLoaded(true);
            }}
          />
          <ul className={'lookbook_info'}>
            {lookbookChart.map((item, index) => (
              <li key={uuid()}>
                {Object.entries(item).map(([key, value]) => (
                  <React.Fragment key={uuid()}>
                    <div>
                      <span>{changeFirstLetter(key)}</span>
                    </div>
                    <div>
                      <a onClick={() => setLookbookProduct(value)}>{value.code}</a>
                    </div>
                  </React.Fragment>
                ))}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div ref={scrollableElRef} className={'product-detail'}>
          <div>
            <span>
              {product.category} — {product.name}
            </span>
            <button onClick={handleClose}>CLOSE</button>
          </div>
          <div>
            <div>
              <picture>
                <source
                  srcSet={`https://ss24.thisisneverthat.com/imgs/webp/Large${productImages[currentImageIndex]}.webp`}
                  type='image/webp'
                />
                <img
                  className={isLoaded ? 'loaded' : ''}
                  width='540'
                  height={isLookbook ? '675' : '540'}
                  src={`https://ss24.thisisneverthat.com/imgs/png/Large${productImages[currentImageIndex]}.png`}
                  alt={`Slide ${currentImageIndex + 1}`}
                  onClick={handleImageClick}
                  onLoad={() => {
                    setIsLoaded(true);
                  }}
                />
              </picture>
            </div>
            <ul className={'bullets'}>
              {productImages.map((_, index) => (
                <li
                  style={{
                    background: index === currentImageIndex ? 'var(--color-text)' : 'var(--color-grey)',
                  }}
                  onClick={() => handleBulletClick(index)}
                  key={uuid()}
                ></li>
              ))}
            </ul>
          </div>
          <ul className={'product_info'}>
            {productChart.map((item, index) => (
              <li className={'product_info_row'} key={uuid()}>
                {Object.entries(item).map(([key, value]) => (
                  <React.Fragment key={uuid()}>
                    <span>{key}</span>
                    <span>{key === 'Colour' ? highlightColor(product, key, value) : value}</span>
                  </React.Fragment>
                ))}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProductDrawer;
