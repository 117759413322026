import React, { useEffect, useRef } from 'react';
import SubMenuItem from 'components/SubMenuItem';
import { useSearchParams } from 'react-router-dom';
import useCheckPC from 'hooks/useCheckPC';
import useLayout from 'hooks/useLayout';

const SubMenuList = ({ type, handleShowLoading, toggleHeaderNav }) => {
  const { isTabletSmall, isMobile } = useCheckPC();

  const listRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { closeSubMenu } = useLayout();

  useEffect(() => {
    if (listRef.current) {
      const listItems = listRef.current.children;

      Array.from(listItems).forEach((item, index) => {
        setTimeout(() => {
          item.classList.add('show');
        }, index * 50);
      });
    }
  }, []);
  const handleInputChange = (type, name) => {
    const newSearchParams =
      type === 'filter'
        ? { filter: name }
        : name !== 'Default'
          ? { sort: name }
          : name === 'Default' && { filter: 'All' };

    setSearchParams(newSearchParams);

    handleShowLoading();
    !isMobile && !isTabletSmall ? closeSubMenu() : toggleHeaderNav();
    window.scrollTo(0, 0);
  };

  return (
    <ul className={'menu-items'} ref={listRef}>
      {subMenus[type].map((name, index) => {
        const filterParam = searchParams.get('filter');
        const sortParam = searchParams.get('sort');

        const isSelected =
          type === 'filter'
            ? filterParam === name ||
              (filterParam === null && (sortParam === null || sortParam === 'Default') ? name === 'All' : '')
            : sortParam === name || (sortParam === null && name === 'Default');

        return (
          <SubMenuItem
            key={name}
            type={type}
            name={name}
            isSelected={isSelected}
            handleInputChange={handleInputChange}
          />
        );
      })}
    </ul>
  );
};

export default SubMenuList;

const subMenus = {
  filter: [
    'All',
    'Outerwear',
    'Shirts',
    'Pants',
    'Knits',
    'Tops',
    'Sweatshirts',
    'Tees',
    'Headwear',
    'Bags',
    'Accessories',
    'Lookbook',
  ],
  sort: ['Default', 'Categories', 'Color', 'Material'],
};
