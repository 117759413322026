import 'App.css';

import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ProductDrawer from 'components/ProductDrawer';
import ProductGrid from 'components/ProductGrid';
import ProductList from 'components/ProductList';

import useCheckPC from 'hooks/useCheckPC';
import { shuffleArray } from 'utils/shuffleArray';
import Loading from 'components/Loading';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FloatingNav from 'components/FloatingNav';
import useLayout from 'hooks/useLayout';

import 'styles/font.css';
import 'styles/reset.css';
import useProducts from 'hooks/useProducts';

function App() {
  const { isPC, isMobile, isTabletSmall, isTabletLarge } = useCheckPC();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [selectedFilter, setSelectedFilter] = useState(searchParams.get('filter') ?? 'All');
  const [selectedSort, setSelectedSort] = useState(searchParams.get('sort') ?? 'Default');

  /** shuffle Icon 클릭 시, load 애니메이션 적용시켜주는 state */
  const [isShuffled, setIsShuffled] = useState(false);

  const [products, setProducts] = useState([]);
  const [lookbook, setLookbook] = useState([]);
  const [allData, setAllData] = useState([]);

  const [isWideImage, setIsWideImage] = useState(false);

  const {
    view,
    isLoading,
    isDrawerOpen,
    openDrawer,
    closeDrawer,
    handleShowLoading,
    handleVisibleFooter,
    resetLayout,
    resetUserLayout,
  } = useLayout();

  const { shuffledProducts, handleProducts } = useProducts();

  /** 새로고침 */
  useEffect(() => {
    handleShowLoading();
    handleVisibleFooter(900);
    if (window.history) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsResponse = await fetch('https://ss24.thisisneverthat.com/products.json');
        const productsData = await productsResponse.json();
        setProducts(productsData);

        const lookbookResponse = await fetch('https://ss24.thisisneverthat.com/lookbook.json');
        const lookbookData = await lookbookResponse.json();
        setLookbook(lookbookData);

        setAllData([...lookbookData, ...productsData]);
      } catch (error) {
        setProducts([]);
        setLookbook([]);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (allData.length > 0) {
      if (shuffledProducts.length > 0) {
        return;
      }
      handleShuffleProducts(allData);
    }
  }, [allData]);

  useEffect(() => {
    if (searchParams.get('product') || searchParams.get('lookbook')) {
      openDrawer();
    } else {
      closeDrawer();
    }
    setSelectedFilter(searchParams.get('filter') ?? 'All');
    setSelectedSort(searchParams.get('sort') ?? 'Default');
  }, [searchParams]);

  const handleShuffleProducts = array => {
    const shuffledArray = shuffleArray(array);
    handleProducts(shuffledArray);
  };

  useEffect(() => {
    if (products.length > 0 && lookbook.length > 0) {
      handleShuffleProducts([...lookbook, ...products]);
    }
  }, [isShuffled]);

  /** 초기화를 시켜주는 함수 */
  const handleInitClick = () => {
    window.scrollTo(0, 0);
    resetLayout();
    resetUserLayout();
    navigate('/');
    handleShowLoading();
    setIsShuffled(false);
  };

  useEffect(() => {
    if (products.length !== 0) {
      const safeAreaEl = document.querySelector('#safe-area');
      if ((isMobile || isTabletSmall) && isDrawerOpen) {
        document.querySelector('#root').style.overflow = 'hidden';
        document.querySelector('#root').style.height = '100dvh';
      } else {
        document.querySelector('#root').style.overflow = 'auto';
        document.querySelector('#root').style.height = 'auto';
      }
      if (isPC) {
        if (isDrawerOpen) {
          document.querySelector('main').style.minHeight = 'calc(100dvh - 40.8rem)';
        } else {
          document.querySelector('main').style.minHeight = 'calc(100dvh - 31.3rem)';
        }
      }
      if (safeAreaEl) {
        if (isTabletSmall || isTabletLarge) {
          document.querySelector('#safe-area').style.width = '100vw';
        } else {
          document.querySelector('#safe-area').style.width = '0';
        }
      }
    }
  }, [isDrawerOpen, isPC, isMobile, isTabletSmall, products]);

  return (
    <>
      {isLoading && <Loading products={shuffledProducts} />}
      <Header handleInitClick={handleInitClick} selectedFilter={selectedFilter} selectedSort={selectedSort} />
      <main
        style={{
          width: `${
            (isPC || isTabletLarge) && isDrawerOpen && view === 'IMAGE' ? 'calc(50% - 1.8rem)' : 'calc(100% - 2.4rem)'
          }`,
        }}
      >
        {view === 'IMAGE' && (
          <ProductGrid
            products={shuffledProducts}
            filter={selectedFilter}
            sort={selectedSort}
            isShuffled={isShuffled}
          />
        )}
        {view === 'TEXT' && (
          <ProductList products={[...products, ...lookbook]} filter={selectedFilter} sort={selectedSort} />
        )}
      </main>
      <Footer />
      {shuffledProducts.length > 0 && isDrawerOpen && (
        <ProductDrawer
          products={shuffledProducts}
          handleClose={() => {
            handleVisibleFooter();

            let productCardId = searchParams.get('product')
              ? `${searchParams.get('product')}-${searchParams.get('color')}`
              : searchParams.get('lookbook');

            if (searchParams.get('sort')) productCardId += '-sorted';
            const newSearchParams = {};
            if (searchParams.get('filter')) newSearchParams['filter'] = searchParams.get('filter');
            if (searchParams.get('sort')) newSearchParams['sort'] = searchParams.get('sort');
            setSearchParams(newSearchParams);
            if (view === 'TEXT' || searchParams.get('filter') === 'Lookbook') {
              return;
            }

            setTimeout(() => {
              document.getElementById(productCardId).scrollIntoView({ block: 'center' });
            }, 200);
          }}
        />
      )}
      {((isMobile || isTabletSmall) && isDrawerOpen) || (
        <FloatingNav isShuffled={isShuffled} setIsShuffled={setIsShuffled} />
      )}
    </>
  );
}

export default App;
