import { useDispatch, useSelector } from 'react-redux';

import { useCallback } from 'react';
import { updateProducts } from 'reducers/products';

export default function useProducts() {
  const dispatch = useDispatch();

  const handleProducts = useCallback(
    shuffledProducts => {
      dispatch(updateProducts(shuffledProducts));
    },
    [dispatch],
  );

  return {
    shuffledProducts: useSelector(state => state.products.shuffledProducts),
    handleProducts,
  };
}
