import React from 'react';

const ProductImage = ({ product, isLoaded, onLoad }) => {
  const isLookbook = product.category === 'Lookbook';
  const isWide = product.wideImage;

  return (
    <div className={isWide ? 'wideImage' : ''}>
      <picture>
        <source
          srcSet={`https://ss24.thisisneverthat.com/imgs/webp/Large${product.image_src_1}.webp`}
          type='image/webp'
        />
        <img
          className={isLoaded ? 'loaded' : ''}
          width={isLookbook && isWide ? '540' : '540'}
          height={isLookbook && isWide ? '432' : '675'}
          src={`https://ss24.thisisneverthat.com/imgs/png/Large${product.image_src_1}.png`}
          alt={product.name}
          onLoad={onLoad}
        />
      </picture>
    </div>
  );
};

export default ProductImage;
