import { createContext, useContext, useEffect, useState } from 'react';

export const DarkModeContext = createContext();

export function DarkModeProvider({ children }) {
  const [darkMode, setDarkMode] = useState(false);
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    updateDarkMode(!darkMode);
  };

  useEffect(() => {
    const isDark = sessionStorage.theme === 'dark';

    setDarkMode(isDark);
    updateDarkMode(isDark);
  }, []);

  return <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>{children}</DarkModeContext.Provider>;
}

function updateDarkMode(darkMode) {
  if (darkMode) {
    document.documentElement.classList.add('dark');
    sessionStorage.theme = 'dark';
  } else {
    document.documentElement.classList.remove('dark');
    sessionStorage.theme = 'light';
  }
}
export const useDarkMode = () => useContext(DarkModeContext);
