export const getRandomNumber = (min, max) => {
  // Ensure that min and max are valid numbers
  if (typeof min !== 'number' || typeof max !== 'number') {
    throw new Error('Both min and max should be numbers');
  }

  // Ensure that min is less than or equal to max
  if (min > max) {
    throw new Error('Min should be less than or equal to max');
  }

  // Calculate the random number within the range (including decimal values)
  const randomNumber = Math.random() * (max - min) + min;

  return randomNumber;
};
