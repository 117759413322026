const initialStateProducts = {
  shuffledProducts: [],
};

const PRODUCTS_UPDATE_SHUFFLEDPRODUCTS = 'PRODUCTS/UPDATE_SHUFFLEDPRODUCTS';

export const updateProducts = shuffledProducts => ({ type: PRODUCTS_UPDATE_SHUFFLEDPRODUCTS, shuffledProducts });

export const products = (state = initialStateProducts, action) => {
  switch (action.type) {
    case PRODUCTS_UPDATE_SHUFFLEDPRODUCTS:
      return {
        ...state,
        shuffledProducts: action.shuffledProducts,
      };
    default:
      return state;
  }
};
