import React from 'react';

import * as icons from './svg';
const Icon = ({ icon, width = '14px', height = '14px', alt, ...props }) => {
  const SVGIcon = icons[icon];

  return (
    <>
      {alt && <span>{alt}</span>}
      <SVGIcon width={width} height={height} {...props} />
    </>
  );
};

export default Icon;
