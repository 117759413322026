import { useEffect, useState, useRef } from 'react';
import ProductListItem from 'components/ProductListItem';
import { getSortProperty } from 'utils/getSortProperty';
import useLayout from 'hooks/useLayout';
import useCheckPC from 'hooks/useCheckPC';

const ProductList = ({ products, filter, sort }) => {
  const { isLoading } = useLayout();
  const { isPC } = useCheckPC();
  const [elements, setElements] = useState([[]]);
  const [sortedProducts, setSortedProducts] = useState([]);
  const ulRef = useRef(null);

  useEffect(() => {
    const getSortedProducts = sort => {
      return [...new Set(products.filter(product => product[sort] !== undefined).map(product => product[sort]))].sort();
    };

    let sortedArray;
    switch (sort) {
      case 'Categories':
        sortedArray = categoryArray;
        break;
      case 'Color':
        sortedArray = getSortedProducts('sort_color');
        break;
      case 'Material':
        sortedArray = getSortedProducts('sort_material');
        break;
      default:
        sortedArray = [];
    }
    setSortedProducts(sortedArray);
    const selectors = [
      'li > span:first-child',
      'li > span:nth-child(2)',
      'li > span:nth-child(3)',
      'li > span:nth-child(4)',
      'li > span:nth-child(5)',
      'li > span:last-child',
    ];
    setElements(selectors.map(selector => ulRef.current.querySelectorAll(selector)));
  }, [products, sort, filter]);

  const addShowClassDelay = (elements, delay) => {
    elements.forEach((span, index) => {
      setTimeout(
        () => {
          span.classList.add('show');
        },
        index * 25 + delay,
      );
    });
  };

  let itemLength = 0;

  if (ulRef.current) {
    itemLength = ulRef.current.children.length;
  }

  useEffect(() => {
    if (!isLoading && ulRef.current) {
      elements.forEach((els, index) => {
        els.forEach((span, index) => {
          span.classList.remove('show');
        });
      });
      elements.forEach((els, index) => {
        addShowClassDelay(els, index * 25);
      });
    }
  }, [elements[0].length, itemLength, isLoading]);

  return (
    <>
      <div className={'text-view'} ref={ulRef}>
        {sort === 'Default' ? (
          <ul className={'product_list'}>
            {products
              .filter(product => filter === 'All' || filter === product.category)
              .map((product, i) => (
                <ProductListItem key={`${product.image_src_1}-${i}`} product={product} />
              ))}
          </ul>
        ) : (
          sortedProducts.map((sortItem, i) => (
            <div key={i}>
              <h2>{sortItem}</h2>
              <ul className={'product_list'}>
                {products
                  .filter(product => product[getSortProperty(sort)] === sortItem)
                  .map((product, index) => (
                    <ProductListItem key={product.image_src_1} product={product} />
                  ))}
              </ul>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default ProductList;

export const categoryArray = [
  'Outerwear',
  'Shirts',
  'Pants',
  'Knits',
  'Tops',
  'Sweatshirts',
  'Tees',
  'Headwear',
  'Bags',
  'Accessories',
  'Lookbook',
];
