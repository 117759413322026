import { useInterval } from 'hooks/useInterval';
import React, { useState } from 'react';

const Timer = () => {
  const [timer, setTimer] = useState('0');

  const currentTimer = () => {
    const date = new Date();
    const options = {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'Asia/Seoul',
    };

    const formattedTime = date.toLocaleString('en-US', options);

    setTimer(`${formattedTime} UTC+0900(KST)`);
  };

  useInterval(currentTimer, 1000);

  return <span className='time'>{timer}</span>;
};

export default Timer;
