import React, { useRef } from 'react';
import useBodyScrollLock from 'hooks/useBodyScrollLock';

const Loading = () => {
  const scrollableElRef = useRef(null);
  useBodyScrollLock(scrollableElRef);

  return (
    <div ref={scrollableElRef} className={'loading'}>
      <p>Please Wait</p>
    </div>
  );
};

export default Loading;
