import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';
import React, { useEffect } from 'react';

import useCheckPC from 'hooks/useCheckPC';

/**
 * 화면 스크롤 고정
 * @param scrollableElRef 고정화면 내에서 스크롤 허용할 영역
 */
export default function useBodyScrollLock(scrollableElRef) {
  const { isPC } = useCheckPC();
  useEffect(() => {
    const element = scrollableElRef.current;
    if (element) disableBodyScroll(element);

    return () => {
      if (element) enableBodyScroll(element);
    };
  }, [isPC]);
}
