import useCheckPC from 'hooks/useCheckPC';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getRandomNumber } from 'utils/getRandomNumber';
import { v4 as uuid } from 'uuid';
import useLayout from 'hooks/useLayout';

const ProductCard = ({ product, isSorted }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { isMobile, isTabletSmall } = useCheckPC();
  const [searchParams, setSearchParams] = useSearchParams();
  const cardRef = useRef(null);
  const { isLoading, isDrawerOpen, handleVisibleFooter } = useLayout();

  const [hoverTextDisplay, setHoverTextDisplay] = useState('');

  useEffect(() => {
    if (isSelectedProduct()) {
      setTimeout(() => {
        if (cardRef.current) {
          cardRef.current.scrollIntoView({ block: 'center' });
        }
      }, 200);
    }
  }, [isDrawerOpen]);

  const isLookbook = product.category === 'Lookbook';
  const isSelectedProduct = () => {
    if (isLookbook) {
      return searchParams.get('lookbook') === product.number;
    } else {
      return searchParams.get('product') === product.code && searchParams.get('color') === product.colour;
    }
  };

  const delay = useMemo(() => {
    return `${getRandomNumber(100, 300)}ms`;
  }, []);

  const productInfo = isLookbook
    ? { number: product.number, name: product.name, category: product.category, productCode: extractCodes(product) }
    : { code: product.code, name: product.name, category: product.category, color: product.colour, size: product.size };

  function extractCodes(data) {
    const codes = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (typeof value === 'object' && value !== null && 'code' in value) {
          if (value.code !== null) {
            codes.push(value.code);
          }
        }
      }
    }
    return codes;
  }

  const elementId = isLookbook ? `${product.number}` : `${product.code}-${product.colour}`;

  useEffect(() => {
    if (!isSelectedProduct()) {
      setTimeout(() => {
        setHoverTextDisplay('');
      }, 200);
    } else {
      setHoverTextDisplay('flex');
    }
  }, [isDrawerOpen, searchParams]);

  return (
    <li
      style={{ transitionDelay: delay }}
      className={!isLoading && isLoaded ? 'loaded' : ''}
      ref={cardRef}
      id={`${elementId}${isSorted ? '-sorted' : ''}`}
      onClick={() => {
        if (!isDrawerOpen) {
          handleVisibleFooter();
        }

        const newSearchParams = isLookbook
          ? { lookbook: product.number }
          : { product: product.code, color: product.colour };
        if (searchParams.get('filter')) {
          newSearchParams['filter'] = searchParams.get('filter');
        }
        if (searchParams.get('sort')) {
          newSearchParams['sort'] = searchParams.get('sort');
        }
        setSearchParams(newSearchParams);
      }}
    >
      <div
        className={!isMobile ? 'hover-text' : 'hidden'}
        style={{ display: !isMobile && !isTabletSmall && hoverTextDisplay }}
      >
        {isLookbook ? (
          <>
            <span>{productInfo.number}</span>
            <span>{productInfo.name}</span>
            <span>{productInfo.category}</span>
            {productInfo.productCode.map((code, i) => (
              <span key={uuid()}>{code}</span>
            ))}
          </>
        ) : (
          <>
            <span>{productInfo.code}</span>
            <span>
              {productInfo.name} {productInfo.color}
            </span>
            <span className='products-hoverText-category'>{productInfo.category}</span>
            <span className='product-hoverText-size'>{productInfo.size}</span>
          </>
        )}
      </div>
      <div>
        <LazyLoadImage
          width='540'
          height={isLookbook ? '675' : '540'}
          src={`https://ss24.thisisneverthat.com/imgs/png/Small${product.image_src_1}.png`}
          alt={product.name}
          onLoad={() => {
            setIsLoaded(true);
          }}
        />
      </div>
    </li>
  );
};

export default ProductCard;
