import Timer from 'components/Timer';
import Icon from 'components/Icon';
import useLayout from 'hooks/useLayout';

const Footer = () => {
  const { isFooterVisible, isDrawerOpen } = useLayout();

  return (
    <footer className={`${isDrawerOpen ? 'drawer-open' : ''} ${isFooterVisible ? 'loaded' : ''}`}>
      <div>
        <Icon width={'298px'} height={'32px'} icon={'Logo'} />
        <Icon width={'200px'} height={'32px'} icon={'Season_title'} />
      </div>
      <div>
        <div>
          <Timer />
        </div>
        <div>
          <nav className={'nav-footer'}>
            <ul className={'connect'}>
              <li>
                <span>CONNECT US</span>
              </li>
              <li>
                <a href='https://thisisneverthat.com/' target='_blank' rel='noreferrer'>
                  <span>Store</span>
                </a>
              </li>
              <li>
                <a href='https://www.instagram.com/thisisneverthat/' target='_blank' rel='noreferrer'>
                  <span>Instagram</span>
                </a>
              </li>
              <li>
                <a href='https://www.youtube.com/Thisisneverthat' target='_blank' rel='noreferrer'>
                  <span>Youtube</span>
                </a>
              </li>
            </ul>
            <ul className={'contact'}>
              <li>
                <span className={''}>CONTACT US</span>
              </li>
              <li>
                <a href='mailto:info@thisisneverthat.com'>
                  <span>info@thisisneverthat.com</span>
                </a>
              </li>
            </ul>
            <span className={'year'}>©2024</span>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
