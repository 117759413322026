import React, { useState } from 'react';

import { useDarkMode } from 'context/DarkModeContext';

import Icon from 'components/Icon';
import Dim from 'components/Dim';
import useLayout from 'hooks/useLayout';
import { useSearchParams } from 'react-router-dom';

const FloatingNav = ({ isShuffled, setIsShuffled }) => {
  const { toggleDarkMode } = useDarkMode();
  const [searchParams] = useSearchParams();

  const { view, gridColumns, setGridColumns } = useLayout();

  const [isRangeInputOpen, setIsRangeInputOpen] = useState(false);
  const handleShuffleProducts = () => {
    setIsShuffled(prev => !prev);
  };

  const handleToggleRangeInput = () => {
    setIsRangeInputOpen(prev => !prev);
  };

  /** Grid 사이즈 조절 함수 */
  const handleInputChange = event => {
    let value = parseInt(event.target.value, 10);
    setGridColumns(value);
  };

  return (
    <div className={'floating-bar'}>
      <div className={'floating-bar__menu'}>
        <button onClick={() => toggleDarkMode()}>
          <Icon icon={'Brightness'} />
        </button>
        {view === 'IMAGE' && searchParams.get('sort') === null && (
          <button onClick={handleShuffleProducts}>
            <Icon icon={isShuffled ? 'Shuffle_back' : 'Shuffle'} />
          </button>
        )}
        {view === 'IMAGE' && (
          <div className={'scale'}>
            <button onClick={handleToggleRangeInput}>
              {isRangeInputOpen ? <Icon icon={'Close'} /> : <Icon icon={'Scale'} />}
            </button>
            {isRangeInputOpen && view === 'IMAGE' && (
              <div className={'range-input'}>
                <input type='range' min={0} max={2} step={1} value={gridColumns} onChange={handleInputChange} />
                <div className={'thumb'} style={{ left: gridColumns * 57 }}></div>
                <div className={'line'}></div>
              </div>
            )}
          </div>
        )}
      </div>
      <div id={'safe-area'}></div>
      {isRangeInputOpen && <Dim onClick={() => setIsRangeInputOpen(false)} />}
    </div>
  );
};

export default FloatingNav;
