import { useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { layoutActionCreator } from 'reducers/layout';
import { userLayoutActionCreator } from 'reducers/userLayout';

export default function useLayout() {
  const dispatch = useDispatch();

  const openSubMenu = useCallback(
    ({ title, cancelButton }) => {
      dispatch(
        layoutActionCreator.openSubMenu({
          title,
          cancelButton,
        }),
      );
    },
    [dispatch],
  );
  /**
   * Filter, Sort 메뉴 닫기
   * @type {(function(): void)|*}
   */
  const closeSubMenu = useCallback(() => {
    dispatch(layoutActionCreator.closeSubMenu());
  }, [dispatch]);

  const openDrawer = useCallback(() => {
    dispatch(layoutActionCreator.openDrawer());
  }, [dispatch]);

  const closeDrawer = useCallback(() => {
    dispatch(layoutActionCreator.closeDrawer());
  }, [dispatch]);

  const openHeader = useCallback(() => {
    dispatch(layoutActionCreator.openHeader());
  }, [dispatch]);

  const closeHeader = useCallback(() => {
    dispatch(layoutActionCreator.closeHeader());
  }, [dispatch]);

  const openLoading = useCallback(() => {
    dispatch(layoutActionCreator.openLoading());
  }, [dispatch]);

  const closeLoading = useCallback(() => {
    dispatch(layoutActionCreator.closeLoading());
  }, [dispatch]);

  const handleShowLoading = useCallback(() => {
    dispatch(layoutActionCreator.openLoading());
    const timeoutId = setTimeout(() => {
      dispatch(layoutActionCreator.closeLoading());
    }, 700);
    return () => clearTimeout(timeoutId);
  }, [dispatch]);

  const handleVisibleFooter = useCallback(
    (delay = 600) => {
      dispatch(layoutActionCreator.hiddenFooter());
      setTimeout(() => {
        dispatch(layoutActionCreator.showFooter());
      }, delay);
    },
    [dispatch],
  );

  const resetLayout = useCallback(() => {
    dispatch(layoutActionCreator.resetLayout());
  }, [dispatch]);

  /** localStorage에 저장할 layout 상태 */
  const setView = useCallback(
    view => {
      dispatch(userLayoutActionCreator.setView(view));
    },
    [dispatch],
  );

  const setGridColumns = useCallback(
    gridColumns => {
      dispatch(userLayoutActionCreator.setGridColumns(gridColumns));
    },
    [dispatch],
  );

  const resetUserLayout = useCallback(() => {
    dispatch(userLayoutActionCreator.resetUserLayout());
  }, [dispatch]);

  return {
    isDrawerOpen: useSelector(state => state.layout.isDrawerOpen),
    isHeaderOpen: useSelector(state => state.layout.isHeaderOpen),
    isLoading: useSelector(state => state.layout.isLoading),
    isSubMenuOpen: useSelector(state => state.layout.isSubMenuOpen),
    isFooterVisible: useSelector(state => state.layout.isFooterVisible),
    view: useSelector(state => state.userLayout.view),
    gridColumns: useSelector(state => state.userLayout.gridColumns),
    subMenu: useSelector(state => state.layout.subMenu),
    openDrawer,
    closeDrawer,
    openSubMenu,
    closeSubMenu,
    openHeader,
    closeHeader,
    openLoading,
    closeLoading,
    handleShowLoading,
    handleVisibleFooter,
    setView,
    setGridColumns,
    resetUserLayout,
    resetLayout,
  };
}
