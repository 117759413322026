import React, { useState, useEffect } from 'react';

const Info = () => {
  const text =
    'Rooted in the sub-cultures of 90’s Seoul, thisisneverthat® is a Korean creative collective and fashion label originally established in 2010. The label creates and produces a variety of content and visuals known as one of the bestselling street wear brand. The group is aimed at tearing down the barriers of established norms in mainstream culture. More or less, it mingles with other creative neighbors like Arts, Music scene to present a sense of newness.';
  const [displayText, setDisplayText] = useState('');
  const [wordIndex, setWordIndex] = useState(0);

  useEffect(() => {
    const words = text.split(' ');

    const interval = setInterval(() => {
      setDisplayText(prevText => prevText + (prevText ? ' ' : '') + words[wordIndex]);

      setWordIndex(prevIndex => {
        const nextIndex = prevIndex + 1;
        if (nextIndex < words.length) {
          return nextIndex;
        } else {
          clearInterval(interval);
          return prevIndex;
        }
      });
    }, 5);

    return () => clearInterval(interval);
  }, [wordIndex]);

  return (
    <ul className={'menu-items info'}>
      <p className={'invisible_text'}>{text}</p>
      <p className={'info_text'}>{displayText}</p>
    </ul>
  );
};

export default Info;
