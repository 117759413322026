import React, { useEffect } from 'react';
import useCheckPC from 'hooks/useCheckPC';
import Dim from 'components/Dim';
import SubMenu from 'components/SubMenu';
import useLayout from 'hooks/useLayout';

const Header = ({ selectedFilter, selectedSort, handleInitClick }) => {
  const { isPC, isTabletLarge, isTabletSmall, isMobile } = useCheckPC();

  const {
    view,
    setView,
    isSubMenuOpen,
    openSubMenu,
    closeSubMenu,
    isHeaderOpen,
    openHeader,
    closeHeader,
    handleShowLoading,
  } = useLayout();

  /** scroll Top */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [view]);
  const openNav = e => {
    e.stopPropagation();
    let title;
    if (e.target.innerText.includes('FILTER')) {
      title = 'FILTER';
    } else if (e.target.innerText.includes('SORT')) {
      title = 'SORT';
    } else if (e.target.innerText.includes('INFO')) {
      title = 'INFO';
    } else {
      title = e.target.innerText;
    }
    openSubMenu({
      title,
      cancelButton: {
        label: 'CLOSE',
      },
    });
  };

  /** View 상태 핸들링 함수 */
  const toggleView = () => {
    const newView = view === 'IMAGE' ? 'TEXT' : 'IMAGE';
    setView(newView);
    handleShowLoading();
    (isMobile || isTabletSmall) && toggleHeaderNav();
  };

  /** 모바일일 때, Header 닫기버튼 핸들링 함수 */
  const toggleHeaderNav = () => {
    if (isHeaderOpen) {
      closeHeader();
    } else {
      openHeader();
    }
  };

  const clickClose = () => {
    if (isPC || isTabletLarge) {
      closeSubMenu();
    } else {
      closeHeader();
      closeSubMenu();
    }
  };

  return (
    <header style={{ backgroundColor: isHeaderOpen && (isMobile || isTabletSmall) ? 'var(--color-bg)' : '' }}>
      <ul className='left-menu'>
        <button onClick={handleInitClick}>thisisneverthat® SS24 ULTRAS Ⅱ</button>
        {(isMobile || isTabletSmall) && (
          <button onClick={toggleHeaderNav}>{!isHeaderOpen ? <span>MENU</span> : <span>CLOSE</span>}</button>
        )}
      </ul>
      {/* 모바일 혹은 태블릿 소형일 때는 isHeaderOpen가 되어야 보이게 함 */}
      {(isHeaderOpen || isPC || isTabletLarge) && (
        <ul className='right-menu'>
          {menus.map((menuGroup, groupIndex) => (
            <li key={groupIndex}>
              {menuGroup.map((menu, index) => (
                <div key={index}>
                  {(menu.name === 'FILTER' || menu.name === 'SORT') && (
                    <button onClick={openNav}>
                      {menu.name}
                      {menu.name === 'FILTER'
                        ? (selectedSort === 'Default' || selectedSort === '') && `(${selectedFilter})`
                        : selectedSort !== 'Default' && selectedSort !== '' && `(${selectedSort})`}
                      {!isMobile && !isTabletSmall && menu.name === 'FILTER' ? ',' : ''}
                    </button>
                  )}
                  {menu.name === 'VIEW' && <button onClick={toggleView}>{view} VIEW</button>}
                  {menu.name === 'INFO' && <button onClick={openNav}>{menu.name}</button>}
                </div>
              ))}
            </li>
          ))}
          {isSubMenuOpen && <SubMenu handleShowLoading={handleShowLoading} toggleHeaderNav={toggleHeaderNav} />}
        </ul>
      )}
      {(isSubMenuOpen || isHeaderOpen) && <Dim onClick={clickClose} />}
    </header>
  );
};
export default Header;

const menus = [[{ name: 'FILTER' }, { name: 'SORT' }], [{ name: 'VIEW' }], [{ name: 'INFO' }]];
