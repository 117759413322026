import React from 'react';

const Dim = ({ onClick }) => {
  return (
    <div
      className={'dim'}
      onClick={e => {
        e.preventDefault();
        onClick && onClick();
      }}
    ></div>
  );
};

export default Dim;
