import React from 'react';
import Info from 'components/Info';
import useCheckPC from 'hooks/useCheckPC';
import SubMenuList from 'components/SubMenuList';
import useLayout from 'hooks/useLayout';

const SubMenu = ({ handleShowLoading, toggleHeaderNav }) => {
  const { isTabletSmall, isMobile } = useCheckPC();

  const { subMenu, closeSubMenu, closeHeader } = useLayout();

  const type = subMenu.title.includes('FILTER')
    ? 'filter'
    : subMenu.title === 'SORT'
      ? 'sort'
      : subMenu.title === 'INFO' && 'info';

  return (
    <div className={'header__navigation'}>
      <div>
        <div className={'header__navigation--title'}>
          <button onClick={closeSubMenu}>{subMenu.title}</button>
          <button onClick={!isMobile && !isTabletSmall ? closeSubMenu : () => closeHeader()}>
            {subMenu.cancelButton.label}
          </button>
        </div>
        {(isTabletSmall || isMobile) && (
          <button onClick={closeSubMenu} className={'back-btn'}>
            <span>← BACK</span>
          </button>
        )}

        {type === 'filter' && (
          <SubMenuList
            type={type}
            navOpen={subMenu}
            handleShowLoading={handleShowLoading}
            toggleHeaderNav={toggleHeaderNav}
          />
        )}

        {type === 'sort' && (
          <SubMenuList
            type={type}
            navOpen={subMenu}
            handleShowLoading={handleShowLoading}
            toggleHeaderNav={toggleHeaderNav}
          />
        )}

        {type === 'info' && <Info />}
      </div>
    </div>
  );
};

export default SubMenu;
