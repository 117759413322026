/** root reducer */
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { layout } from './layout';
import { userLayout } from './userLayout';
import { products } from './products';

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const rootReducer = combineReducers({
  layout,
  userLayout,
  products,
});

export default persistReducer(persistConfig, rootReducer);
