import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import useCheckPC from 'hooks/useCheckPC';
import { getSortProperty } from 'utils/getSortProperty';
import ProductCard from 'components/ProductCard';
import { categoryArray } from 'components/ProductList';
import useLayout from 'hooks/useLayout';

const ProductGrid = ({ products, filter, sort, isShuffled }) => {
  const { isPC, isTabletLarge, isTabletSmall } = useCheckPC();
  const [sortedProducts, setSortedProducts] = useState([]);
  const ulRef = useRef(null);
  const sortedUlRef = useRef(null);

  const { gridColumns, isDrawerOpen } = useLayout();

  useEffect(() => {
    const getSortedProducts = sort => {
      return [...new Set(products.filter(product => product[sort] !== undefined).map(product => product[sort]))].sort();
    };

    let sortedArray;
    switch (sort) {
      case 'Categories':
        sortedArray = categoryArray;
        break;
      case 'Color':
        sortedArray = getSortedProducts('sort_color');
        break;
      case 'Material':
        sortedArray = getSortedProducts('sort_material');
        break;
      default:
        sortedArray = [];
    }
    setSortedProducts(sortedArray);
  }, [products, sort]);

  /** sorted products의 title 애니메이션 */
  useEffect(() => {
    const fetchData = async () => {
      if (sortedUlRef.current) {
        await new Promise(resolve => setTimeout(resolve, 1));

        const titleItems = sortedUlRef.current.querySelectorAll('div > h2');

        addLoadedClassDelay(titleItems, 500, false);
      }
    };

    fetchData();
  }, [sort, products]);

  /** Drawer가 열리고 닫힐때 load 애니메이션 적용 */
  useLayoutEffect(() => {
    if (sort === 'Default') {
      if (ulRef.current) {
        const listItems = ulRef.current.children;
        addLoadedClassDelay(listItems, 300, true);
      }
    } else {
      if (sortedUlRef.current) {
        const listItems = sortedUlRef.current.querySelectorAll('div > ul > li');
        const titleItems = sortedUlRef.current.querySelectorAll('div > h2');
        addLoadedClassDelay(listItems, 300, true);
        addLoadedClassDelay(titleItems, 500, true);
      }
    }
  }, [isDrawerOpen, isShuffled]);

  const addLoadedClassDelay = (items, delayTime, isListItem) => {
    const filteredItems = isListItem
      ? Array.from(items).filter(item => item.classList.contains('loaded'))
      : Array.from(items);

    filteredItems.forEach(item => {
      item.classList.remove('loaded');
      const delay = Number(item.style.transitionDelay.replace('ms', '')) + delayTime;

      setTimeout(() => {
        item.classList.add('loaded');
      }, delay);
    });
  };

  const handleGrid = gridColumns => {
    const baseColumns = isPC ? 3 : isTabletLarge ? 3 : isTabletSmall ? 2 : 1;
    const adjustedGridColumns = (isPC ? 4 : 3) - gridColumns;

    if (isDrawerOpen) {
      const grid = (adjustedGridColumns * baseColumns) / 2;
      return !isPC && gridColumns === 2 ? Math.ceil(grid) : parseInt(grid);
    } else {
      return baseColumns * adjustedGridColumns;
    }
  };

  const gridClassName = gridColumns => {
    const handleGridValue = handleGrid(gridColumns);

    switch (handleGridValue) {
      case 12:
        return 'grid_12';

      case 9:
        return 'grid_9';

      case 6:
        if (isDrawerOpen) return 'grid_12';
        return 'grid_6';

      case 4:
        return 'grid_4';

      case 3:
        if (isDrawerOpen) return 'grid_6';
        return 'grid_3';

      case 2:
        return 'grid_2';

      default:
        return 'products_grid';
    }
  };

  const isSortedProducts = sortedProducts.length > 0;

  return (
    <>
      {/* filter products */}
      <ul
        style={{
          gridTemplateColumns: `repeat(${handleGrid(gridColumns)}, minmax(0, 1fr))`,
        }}
        className={`products ${gridClassName(gridColumns)} filtered_products ${isSortedProducts ? 'hidden' : ''}`}
        ref={ulRef}
      >
        {products
          .filter(product => filter === 'All' || filter === product.category)
          .map((product, i) => (
            <ProductCard
              key={`${gridColumns}-${product.image_src_1}`}
              product={product}
              isLast={i === products.length - 1}
            />
          ))}
      </ul>
      {/* sort products */}
      <div className={'sorted-products'} ref={sortedUlRef}>
        {sortedProducts.map((sortItem, i) => (
          <div key={i}>
            <h2>{sortItem}</h2>
            <ul
              style={{ gridTemplateColumns: `repeat(${handleGrid(gridColumns)}, minmax(0, 1fr))` }}
              className={`products ${gridClassName(gridColumns)}`}
            >
              {products
                .filter(product => product[getSortProperty(sort)] === sortItem)
                .map((product, index) => (
                  <ProductCard key={`${gridColumns}-${product.image_src_1}`} product={product} isSorted={true} />
                ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};
export default ProductGrid;
