export const getSortProperty = sort => {
  switch (sort) {
    case 'Categories':
      return 'category';
    case 'Color':
      return 'sort_color';
    case 'Material':
      return 'sort_material';
    default:
      return '';
  }
};
